export const environment = {
  appHost: 'https://dev2.hub.veratrak.com',
  apiHost: 'https://xyo9xb8gm6.execute-api.eu-west-1.amazonaws.com/Prod',
  awsHost:
    'https://d-936748b868.awsapps.com/start/#/console?account_id=533267038303&destination=',
  cognito: {
    poolName: 'watchtower-dev2',
    clientId: '5j11sifl8qou3ahfvviib9plgt',
    redirect: 'https://dev2.watchtower.hub.veratrak.com',
  },
};
