import { Injectable } from '@angular/core';

import { TokenObject } from '../models/auth.model';
import { environment } from '@src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {
  baseUrl = `https://${environment.cognito.poolName}.auth.eu-west-1.amazoncognito.com`;
  queryParams = `?client_id=${environment.cognito.clientId}&response_type=token&scope=email+openid&redirect_uri=${encodeURIComponent(environment.cognito.redirect)}`;
  subject = new BehaviorSubject(false);

  get user(): { email: string | null; groups: string } {
    return {
      email: localStorage.getItem('email'),
      groups: JSON.parse(localStorage.getItem('groups') ?? '[]'),
    };
  }

  get token(): string {
    return localStorage.getItem('id_token') ?? '';
  }

  isAuthenticated(): boolean {
    const token = localStorage.getItem('id_token') ?? null;
    const exp = localStorage.getItem('exp') ?? '0';
    const valid = new Date(parseInt(exp) * 1000).getTime() > Date.now();

    return !!token && valid;
  }

  canView(scope: string) {
    return ['admin', `${scope}:view`, `${scope}:manage`, 'view:all'].some(r => this.user.groups.includes(r));
  }

  canManage(scope: string) {
    return ['admin', `${scope}:manage`].some(r => this.user.groups.includes(r));
  }

  init(token: string): void {
    const tokenObj = this.parseJwt(token);
    localStorage.setItem('email', tokenObj.email);
    localStorage.setItem('exp', tokenObj.exp.toString());
    localStorage.setItem('groups', JSON.stringify(tokenObj['cognito:groups']));
    localStorage.setItem('id_token', token);
    this.subject.next(true);
  }

  ready() {
    if (this.isAuthenticated()) {
      this.subject.next(true);
    }
    return this.subject.asObservable();
  }

  login(): void {
    window.location.href = `${this.baseUrl}/oauth2/authorize${this.queryParams}/auth`;
  }

  logout(): void {
    localStorage.removeItem('email');
    localStorage.removeItem('groups');
    localStorage.removeItem('id_token');
    window.location.href = `${this.baseUrl}/logout${this.queryParams}/auth`;
  }

  private parseJwt(token: string): TokenObject {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }
}
